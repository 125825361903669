import {end} from '@jetCommon/api.js';
import AbsenceCalendarApiCommon from '@jetCommon/api/absence-calendar.js';
import AbsenceCalendarSettingsApi from '@/api/absence-calendar-settings.js';
import AccountantApiCommon from '@jetCommon/api/accountant.js';
import AciCarCostApiCommon from '@jetCommon/api/aci-car-cost.js';
import AdminApi from '@/api/admin.js';
import AgendaItemsApiCommon from '@/api/agenda-items.js';
import AuthApiCommon from '@jetCommon/api/auth.js';
import CCNLApiCommon from '@jetCommon/data-stores-api/ccnl.js';
import ClockingApi from '@/api/clocking.js';
import CompanyAbsenceKindCommon from '@jetCommon/api/company-absence-kind.js';
import CompanyApi from '@/api/company.js';
import CompanyBoardDocumentApiCommon from '@jetCommon/api/company-board-document.js';
import CompanyCCNLSettingsApiCommon from '@jetCommon/api/company-ccnl-settings.js';
import CompanyClockingPolicyApi from '@/api/company-clocking-policy.js';
import CompanyDocumentApiCommon from '@jetCommon/api/company-document.js';
import CompanyExpensePolicyApi from '@/api/company-expense-policy.js';
import CompanyHeadquartersPolicyApi from '@/api/company-headquarters-policy.js';
import CompanyMealVoucherPoliciesApi from '@/api/company-meal-voucher-policies.js';
import CompanyOnboardingTaskApi from '@/api/company-onboarding-task.js';
import CompanyOnboardingTaskDocumentApi from '@/api/company-onboarding-task-document.js';
import CompanyPaymentApiCommon from '@jetCommon/api/company-payment.js';
import CompanyPayslipExtraBonusKindApiCommon from '@jetCommon/api/company-payslip-extra-bonus-kind.js';
import CompanySalaryPaymentSettingsApi from '@/api/company-salary-payment-settings.js';
import CompanySettingsApi from '@/api/company-settings.js';
import CompanyTripDailyRefundPolicyApi from '@/api/company-trip-daily-refund-policy.js';
import CompanyWorkingHoursApiCommon from '@jetCommon/api/company-working-hours.js';
import EmployeeApi from '@/api/employee.js';
import EmployeeCustomFieldApi from './employee-custom-field.js';
import EmployeeDocumentApiCommon from '@jetCommon/api/employee-document.js';
import EmployeeHiringApi from '@/api/employee-hiring.js';
import EmployeeMonthlyReportApiCommon from '@jetCommon/api/employee-monthly-report.js';
import EmployeeShiftApiCommon from '@jetCommon/api/employee-shift.js';
import EmployeeWorkingHoursApiCommon from '@jetCommon/api/employee-working-hours.js';
import ExpenseApiCommon from '@jetCommon/api/expense.js';
import HiringApi from '@/api/hiring.js';
import HiringDocumentApi from '@/api/hiring-document.js';
import HolidaysApiCommon from '@jetCommon/api/holidays';
import LocationApiCommon from '@jetCommon/api/location.js';
import ManagerApiCommon from '@jetCommon/api/manager.js';
import MonthlyReportApi from '@jetCommon/api/monthly-report.js';
import PayrollApiCommon from '@jetCommon/api/payroll.js';
import PayrollAttachmentsApiCommon from '@jetCommon/api/payroll-attachments';
import PayrollEmployeeMealVoucherApi from '@/api/payroll_employee_meal_voucher.js';
import PayslipApi from '@jetCommon/api/payslip.js';
import PayslipExtrasApi from '@/api/payslip-extras.js';
import PersonAddressApiCommon from '@jetCommon/api/person-address.js';
import PersonApiCommon from '@jetCommon/api/person.js';
import SafetyAndHealthApi from '@/api/safety-and-health.js';
import SetupApi from '@/api/setup.js';
import SignatureRequestApi from '@/api/signature-request.js';
import UploadedFilesApiCommon from '@jetCommon/api/uploadedFiles.js';

import AbsenceRequestApi from './absence-request';
export const api = {
    end,
    absenceCalendar: new AbsenceCalendarApiCommon(),
    absenceCalendarSettings: new AbsenceCalendarSettingsApi(),
    absenceRequests: new AbsenceRequestApi(),
    aciCarCosts: new AciCarCostApiCommon(),
    accountants: new AccountantApiCommon(),
    admins: new AdminApi(),
    agendaItems: new AgendaItemsApiCommon(),
    auth: new AuthApiCommon(),
    ccnl: new CCNLApiCommon(),
    companyAbsenceKinds: new CompanyAbsenceKindCommon(),
    companies: new CompanyApi(),
    companyMealVoucherPolicies: new CompanyMealVoucherPoliciesApi(),
    companySalaryPaymentSettings: new CompanySalaryPaymentSettingsApi(),
    companySettings: new CompanySettingsApi(),
    companyCCNLSettings: new CompanyCCNLSettingsApiCommon(),
    companyDocuments: new CompanyDocumentApiCommon(),
    companyBoardDocuments: new CompanyBoardDocumentApiCommon(),
    companyExpensePolicy: new CompanyExpensePolicyApi(),
    companyClockingPolicy: new CompanyClockingPolicyApi(),
    companyHeadquartersPolicy: new CompanyHeadquartersPolicyApi(),
    companyTripDailyRefundPolicy: new CompanyTripDailyRefundPolicyApi(),
    companyOnboardingTaskDocuments: new CompanyOnboardingTaskDocumentApi(),
    companyOnboardingTasks: new CompanyOnboardingTaskApi(),
    companyPayments: new CompanyPaymentApiCommon(),
    companyPayslipExtraBonusKinds: new CompanyPayslipExtraBonusKindApiCommon(),
    companyWorkingHours: new CompanyWorkingHoursApiCommon(),
    clocking: new ClockingApi(),
    employeeCustomFields: new EmployeeCustomFieldApi(),
    employeeDocuments: new EmployeeDocumentApiCommon(),
    employeeMonthlyReports: new EmployeeMonthlyReportApiCommon(),
    employeeShift: new EmployeeShiftApiCommon(),
    employees: new EmployeeApi(),
    employeeWorkingHours: new EmployeeWorkingHoursApiCommon(),
    expenses: new ExpenseApiCommon(),
    hirings: new HiringApi(),
    hiringDocuments: new HiringDocumentApi(),
    holidays: new HolidaysApiCommon(),
    employeeHirings: new EmployeeHiringApi(),
    locations: new LocationApiCommon(),
    managers: new ManagerApiCommon(),
    monthlyReports: new MonthlyReportApi(),
    payrollAttachments: new PayrollAttachmentsApiCommon(),
    payrollEmployeeMealVouchers: new PayrollEmployeeMealVoucherApi(),
    payrolls: new PayrollApiCommon(),
    payslipExtras: new PayslipExtrasApi(),
    payslips: new PayslipApi(),
    personAddresses: new PersonAddressApiCommon(),
    persons: new PersonApiCommon(),
    safetyAndHealth: new SafetyAndHealthApi(),
    setup: new SetupApi(),
    signatureRequests: new SignatureRequestApi(),
    uploadedFiles: new UploadedFilesApiCommon(),
};
